export interface InventoryBrandContract {
  name: string;
  id: string;
}

export class InventoryBrand {
  name: string;
  id: string;

  // https://stackoverflow.com/questions/51763745/angular-6-error-typeerror-is-not-a-function-but-it-is
  constructor(contract: InventoryBrandContract) {
    Object.assign(this, contract);
  }
}
