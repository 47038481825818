<div *ngIf="!fetched" class="spinner">
  <ngx-spinner></ngx-spinner>
</div>

<div *ngIf="fetched" class="container">
  <aside>
    <div class="title-flex">Shades</div>
    <div class="box" *ngFor="let category of categories; index as i">
      <mat-checkbox [checked]="categoryChecked(category)"
                    (change)="categoryChange($event, category)">{{category.name}}</mat-checkbox>
    </div>
    <div class="title-flex">Fancy Features</div>
    <mat-checkbox class="box" [checked]="translucentOnlyChecked"
                  (change)="translucentChange($event)">Translucent</mat-checkbox>
    <mat-checkbox class="box" [checked]="shinyOnlyChecked"
                  (change)="shinyChange($event)">Shiny</mat-checkbox>
    <mat-checkbox class="box" [checked]="sparklyOnlyChecked"
                  (change)="sparklyChange($event)">Sparkly</mat-checkbox>

  </aside>

  <aside>
    <div class="title-flex">Price Tier</div>
    <div class="box" *ngFor="let priceTier of priceTiers; index as i">
      <mat-checkbox [checked]="tierChecked(priceTier)"
                    (change)="tierChange($event, priceTier)">{{priceTier.name}}</mat-checkbox>
    </div>
    <div class="clarifyer">Pricing is dependent on the exact item being printed. Check the listing for the exact price difference.</div>
  </aside>

  <main>
    <div *ngIf="filteredColors.length == 0" class="empty">
        No colors found, please try adjusting your filters.
    </div>
    <div class="content-box">
      <div *ngFor="let color of filteredColors">
        <mat-card class="com-card">
          <mat-card-title>{{color.name}}</mat-card-title>
          <mat-card-subtitle>{{getPriceTierName(color.priceTier)}}</mat-card-subtitle>
         <img mat-card-image src="{{rootStorageUrl}}{{color.id}}.jpg" id="responsive-image"  alt="">
        </mat-card>
      </div>
    </div>
  </main>
</div>
