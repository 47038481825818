export interface ColorContract {
  name: string;
  shiny: boolean;
  translucent: boolean;
  sparkly: boolean;
  priceTier: number;
  colorCategory: string;
  id: string;
}

export class ColorAirtable {
  name: string;
  shiny: boolean;
  translucent: boolean;
  sparkly: boolean;
  priceTier: number;
  colorCategory: string;
  id: string;

  // https://stackoverflow.com/questions/51763745/angular-6-error-typeerror-is-not-a-function-but-it-is
  constructor(contract: ColorContract) {
    Object.assign(this, contract);
  }
}
