import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SessionService} from './session.service';
import {tap} from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public sessionService: SessionService) {
    console.log('Interceptor');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('Intercept2');
    const token = this.sessionService.getToken();
    if (token != null && token !== '') {
      console.log('Token split is ' + token.slice(0, 10));

      console.log('Sending token');
      // console.log(token);
      const encodedToken = btoa(token);
      // console.log(encodedToken);
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${encodedToken}`
        }
      });
    } else {
      console.log('No token available');
    }

    return next.handle(request).pipe( tap(() => {},
      (err: any) => {
        console.log('Monkey catches something');
        // console.log(err);
        // if (err instanceof HttpErrorResponse) {
        //   console.log('Monkey catches Http error');
        //   if (err.status === 426) {
        //     console.log('Monkey says no account!!');
        //     // this.sessionService.logout();
        //     // this.router.navigate(['../login']);
        //   } else {
        //     return;
        //   }
        // }
      }));
  }
}
