import { Injectable } from '@angular/core';
import firebase from 'firebase';
import {AngularFireAuth} from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private token = '';

  constructor(public auth: AngularFireAuth) {
    auth.user.subscribe(user => {
      if (user != null) {
        console.log('Logged in');
        user.getIdToken().then(result => {
          this.token = result;
        });
      } else {
        this.token = '';
        console.log('FAIL');
      }
    });
  }

  getToken(): string {
    return this.token;
  }

  login() {
    this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  logout() {
    this.auth.signOut();
  }
}
