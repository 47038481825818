export interface InventoryLocationContract {
  name: string;
  id: string;
}

export class InventoryLocation {
  name: string;
  id: string;

  // https://stackoverflow.com/questions/51763745/angular-6-error-typeerror-is-not-a-function-but-it-is
  constructor(contract: InventoryLocationContract) {
    Object.assign(this, contract);
  }
}
