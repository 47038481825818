import {Spinner} from 'ngx-spinner/lib/ngx-spinner.enum';

export class Utils {
  static getSpinner(): Spinner {
    return {
      type: 'square-jelly-box',
      size: 'large',
      bdColor: 'rgba(100,167,255,0)',
      color: '#0094d2',
      fullScreen: false
    };
  }

  static logObject(object: any) {
    console.log(JSON.parse(JSON.stringify(object)));
  }
}


