export interface InventoryColorContract {
  name: string;
  brandColor: string;
  brand: string[];
  stock: number;
  stockLocation: string;
  id: string;
}

export class InventoryColor {
  name: string;
  brandColor: string;
  stock: number;
  brand: string[];
  stockLocation: string;
  id: string;

  // https://stackoverflow.com/questions/51763745/angular-6-error-typeerror-is-not-a-function-but-it-is
  constructor(contract: InventoryColorContract) {
    Object.assign(this, contract);
  }

  getBrandId() {
    return this.brand[0];
  }

  decreaseStock() {
    this.stock--;
  }
}
