import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ColorResponseAirtable} from './model/ColorResponseAirtable';
import {InventoryResponse} from './model/InventoryResponse';
import {InventoryColor} from './model/InventoryColor';
import {InventoryUpdateResponse, InventoryUpdateResponseContract} from './model/InventoryUpdateResponse';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  private url = 'https://us-central1-etsy-43803.cloudfunctions.net/app';
  // private url = 'https://us-central1-etsy-43803.cloudfunctions.net/beta;
  // private url = 'http://localhost:4000';

  constructor(private http: HttpClient) {
  }

  getColorsAirtable(): Observable<ColorResponseAirtable> {
    console.log('Backend getting colors');
    return this.http.get<ColorResponseAirtable>(this.url + '/getColors')
      .pipe(
        map(contract => new ColorResponseAirtable(contract)),
        tap(_ => console.log('fetched colors')),
        catchError(this.handleError<ColorResponseAirtable>('getColorsAirtable', new ColorResponseAirtable(null)))
      );
  }

  getInventory(): Observable<InventoryResponse> {
    console.log('Backend getting inventory');
    return this.http.get<InventoryResponse>(this.url + '/getInventory')
      .pipe(
        map(contract => new InventoryResponse(contract)),
        tap(_ => console.log('fetched inventory')),
        catchError(this.handleError<InventoryResponse>('getInventoryAirtable', new InventoryResponse(null)))
      );
  }

  // Needs to be built on backend
  // updateInventory(color: InventoryColor): Observable<InventoryColor> {
  //   console.log('Backend updating inventory');
  //   return this.http.post<InventoryColor>(this.url + '/updateInventory', {id: color.id, stock: color.stock})
  //     .pipe(
  //       tap(res => console.log(res)),
  //       map(contract => new InventoryColor(contract)),
  //       tap(_ => console.log('updated inventory')),
  //       catchError(this.handleError<InventoryColor>('updateInventory', new InventoryColor(null)))
  //     );
  // }

  decreaseStock(color: InventoryColor): Observable<InventoryUpdateResponse> {
    console.log('Backend decreasing stock');
    return this.http.post<InventoryUpdateResponse>(this.url + '/decreaseStock', {id: color.id, stock: color.stock})
      .pipe(
        map(contract => new InventoryUpdateResponse(contract)),
        tap(_ => console.log('updated inventory')),
        catchError(this.handleError<InventoryUpdateResponse>('decreaseStock', new InventoryUpdateResponse(null)))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
