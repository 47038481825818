import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {

  colorId: String;

  constructor(
    private route: ActivatedRoute) {
    route.queryParamMap.subscribe((params => {
      console.log('params', params);
    }));
  }

  ngOnInit() {
    this.getColor();
  }

  private getColor() {
    this.colorId = this.route.snapshot.paramMap.get('id');
  }
}
