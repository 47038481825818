import { Component, OnInit } from '@angular/core';
import {SessionService} from '../session.service';
import {BackendService} from '../server/backend.service';
import {InventoryColor} from '../server/model/InventoryColor';
import {InventoryLocation} from '../server/model/InventoryLocation';
import {InventoryBrand} from '../server/model/InventoryBrand';
import {Utils} from '../utils';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {

  auth;
  colors: InventoryColor[];
  locations: InventoryLocation[];
  brands: BrandSet[] = [];

  constructor(private session: SessionService,
              private backend: BackendService) {
    this.auth = session.auth;
    session.auth.user.subscribe(user => {
      if (user != null) {
        this.backend.getInventory().subscribe(result => {
          console.log(result);
          const temp = result.brands.sort((a, b) => a.name.localeCompare(b.name));
          temp.forEach(brand => {
            const brandSet = new BrandSet();
            brandSet.name = brand.name;
            const tempColors = result.colors.filter(a => a.getBrandId() === brand.id);
            // Utils.logObject(tempColors);
            brandSet.colors = tempColors.filter(a => a.stock > 0)
                              .sort((a, b) => a.brandColor.localeCompare(b.brandColor));
            if (brandSet.colors.length > 0) {
              this.brands.push(brandSet);
            }
          });
        });
      }
    });
  }

  ngOnInit(): void {
  }

  login() {
    this.session.login();
  }

  logout() {
    this.session.logout();
  }

}

class BrandSet {
  name: string;
  colors: InventoryColor[];
}
