import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFireStorage} from '@angular/fire/storage';
import {BackendService} from '../server/backend.service';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {ColorAirtable} from '../server/model/ColorAirtable';
import {ColorCategoryAirtable} from '../server/model/ColorCategoryAirtable';
import {PriceTierAirtable} from '../server/model/PriceTierAirtable';
import {Utils} from '../utils';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-colors',
  templateUrl: './colors.component.html',
  styleUrls: ['./colors.component.scss']
})

export class ColorsComponent implements OnInit {
  filteredColors: ColorAirtable[];
  categories;
  colorColumns = 4;
  categoryColumns = 4;
  priceTiers;

  displayDetails = false;

  rootStorageUrl = 'https://storage.googleapis.com/etsy-43803.appspot.com/images/thumbs/';

  private CATEGORY_ID = 'categoryId';
  private TIER_ID = 'tierId';
  private TRANSLUCENT = 'translucent';
  private SHINY = 'shiny';
  private SPARKLY = 'sparkly';

  private checkedCategories: string[];
  private checkedTiers: string[];
  translucentOnlyChecked = false;
  shinyOnlyChecked = false;
  sparklyOnlyChecked = false;
  allColors: ColorAirtable[] = [];
  fetched = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private storage: AngularFireStorage,
              private backend: BackendService,
              private spinner: NgxSpinnerService) {

    route.queryParams.subscribe(params => {
      console.log(params);
      const queryParamMap = this.route.snapshot.queryParamMap;
      const categoryParams = queryParamMap.get(this.CATEGORY_ID);
      if (categoryParams != null) {
        this.checkedCategories = categoryParams.split(',');
      } else {
        this.checkedCategories = [];
      }

      const tierParams = queryParamMap.get(this.TIER_ID);
      if (tierParams != null) {
        this.checkedTiers = tierParams.split(',');
      } else {
        this.checkedTiers = [];
      }

      this.sparklyOnlyChecked = !!queryParamMap.get(this.SPARKLY);
      this.shinyOnlyChecked = !!queryParamMap.get(this.SHINY);
      this.translucentOnlyChecked = !!queryParamMap.get(this.TRANSLUCENT);

      this.displayColors();
    });
  }

  // https://itnext.io/using-angulars-router-to-manage-state-14b1142a2ffb
  ngOnInit() {
    this.setColumns(window.innerWidth);

    this.spinner.show(undefined, Utils.getSpinner());
    this.backend.getColorsAirtable().subscribe(response => {
      this.spinner.hide();
      this.fetched = true;
      this.allColors = response.colors;
      this.categories = response.colorCategories;
      this.priceTiers = response.priceTiers;
      console.log(response);
      this.displayColors();
    });
  }

  categoryChange(event: MatCheckboxChange, category: ColorCategoryAirtable) {
    const paramMap = this.checkedCategories;
    const id = category.id.toString();
    this.genericListChange(!event.checked, this.CATEGORY_ID, paramMap, id);
  }

  tierChange(event: MatCheckboxChange, tier: PriceTierAirtable) {
    const paramMap = this.checkedTiers;
    const id = tier.id.toString();
    this.genericListChange(!event.checked, this.TIER_ID, paramMap, id);
  }

  private genericListChange(add: Boolean, apiString: string, paramMap: string[], id: string) {
    const newParams = {};
    if (add) {
      paramMap.push(id);
      newParams[apiString] = paramMap.join(',');
    } else {
      if (paramMap.includes(id)) {
        const index: number = paramMap.indexOf(id);
        if (index !== -1) {
          paramMap.splice(index, 1);
        }
      }
      if (paramMap.length > 0) {
        newParams[apiString] = paramMap.join(',');
      }
    }

    this.navigate(newParams, apiString);
  }

  private navigate(newParams: {}, apiString: string) {
    if (apiString !== this.CATEGORY_ID && this.checkedCategories.length > 0) {
      newParams[this.CATEGORY_ID] = this.checkedCategories.join(',');
    }
    if (apiString !== this.TIER_ID && this.checkedTiers.length > 0) {
      newParams[this.TIER_ID] = this.checkedTiers.join(',');
    }

    if (apiString !== this.SPARKLY && this.sparklyOnlyChecked) {
      newParams[this.SPARKLY] = true;
    }

    if (apiString !== this.TRANSLUCENT && this.translucentOnlyChecked) {
      newParams[this.TRANSLUCENT] = true;
    }

    if (apiString !== this.SHINY && this.shinyOnlyChecked) {
      newParams[this.SHINY] = true;
    }

    this.router.navigate(['../colors'], {
      queryParams: newParams
    });
  }

  sparklyChange($event: MatCheckboxChange) {
    this.genericBooleanChange($event.checked, this.SPARKLY);
  }

  shinyChange($event: MatCheckboxChange) {
    this.genericBooleanChange($event.checked, this.SHINY);
  }

  translucentChange($event: MatCheckboxChange) {
    this.genericBooleanChange($event.checked, this.TRANSLUCENT);
  }

  private genericBooleanChange(add: Boolean, apiString: string) {
    const newParams = {};

    if (add) {
      newParams[apiString] = true;
    }

    this.navigate(newParams, apiString);
  }

  private displayColors() {
    console.log('Displaying colors');
    this.filteredColors = [];
    // console.log(JSON.stringify(this.colors));
    console.log('Displaying');

    this.allColors.forEach( (color) => {
      let allowedCategory = true;
      let allowedPriceTier = true;
      let allowedTranslucent = true;
      let allowedShiny = true;
      let allowedSparkly = true;

      if (this.checkedCategories.includes(color.colorCategory.toString())) {
        allowedCategory = false;
      }

      if (this.checkedTiers.includes(color.priceTier.toString())) {
        allowedPriceTier = false;
      }

      if (this.sparklyOnlyChecked && !color.sparkly) {
        allowedSparkly = false;
      }

      if (this.shinyOnlyChecked && !color.shiny) {
        allowedShiny = false;
      }

      if (this.translucentOnlyChecked && !color.translucent) {
        allowedTranslucent = false;
      }

      if (allowedPriceTier && allowedCategory && allowedTranslucent && allowedShiny && allowedSparkly) {
        // console.log('Adding ' + color.name);
        this.filteredColors.push(color);
      }
    });
  }

  onResize(event: any) {
    this.setColumns(event.target.innerWidth);
  }

  setColumns(size: number) {
    if (size <= 400) {
      this.colorColumns = 1;
      this.categoryColumns = 1;
    } else if (size <= 800) {
      this.colorColumns = 2;
      this.categoryColumns = 3;
    } else if (size <= 1200) {
      this.colorColumns = 3;
      this.categoryColumns = 4;
    } else {
      this.colorColumns = 4;
      this.categoryColumns = 6;
    }
  }

  imageLoad() {
    this.displayDetails = true;
  }

  // TODO this isn't working yet
  // https://stackoverflow.com/questions/49270075/how-to-get-the-downoladurl-from-files-uploaded-with-angularfirestorage-reference?
  getImageUrl(color: ColorAirtable) {
    const ref = this.storage.ref(`images/thumbs/${color.id}.jpg`);
    return ref.getDownloadURL();
  }

  categoryChecked(category: ColorCategoryAirtable): boolean {
    return !this.checkedCategories.includes(category.id.toString());
  }

  tierChecked(priceTier: PriceTierAirtable): boolean {
    return !this.checkedTiers.includes(priceTier.id.toString());
  }

  getPriceTierName(id: number) {
    // tslint:disable-next-line:triple-equals
    return this.priceTiers.find(item => item.id == id).name;
  }
}
