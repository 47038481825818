import {Component, Input, OnInit} from '@angular/core';
import {InventoryColor} from '../../server/model/InventoryColor';
import {BackendService} from '../../server/backend.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-inventory-color-card',
  templateUrl: './inventory-color-card.component.html',
  styleUrls: ['./inventory-color-card.component.css']
})
export class InventoryColorCardComponent implements OnInit {

  enabled: Boolean = true;

  @Input()
  color: InventoryColor;

  constructor(
    private backend: BackendService, private snackbar: MatSnackBar) { }

  ngOnInit(): void {
  }

  updateInventory(color: InventoryColor) {
    this.enabled = false;
    this.backend.decreaseStock(color).subscribe(response => {
      this.enabled = true;
      this.color.stock = response.color.stock;
      console.log('Updated?');
      console.log(response);
      console.log(response.color.stock);

      if (!response.result) {
        this.snackbar.open('Stock mismatch, quantity updated', null, {
          duration: 3000
        });
      } else {
        this.snackbar.open('Stock removed', null, {
          duration: 3000
        });
      }
    });
  }
}
