<div class="com-container">
  <div *ngIf="auth.user | async as user; else showLogin">
    <button (click)="logout()">Logout</button>
    <mat-tab-group>
      <mat-tab *ngFor="let brand of brands" label="{{brand.name}}">
        <div class="com-color-container">
          <div *ngFor="let color of brand.colors">
            <app-inventory-color-card [color]=color></app-inventory-color-card>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <ng-template #showLogin>
    <p>Please login.</p>
    <button (click)="login()">Login with Google</button>
  </ng-template>
</div>
