import * as shuffler from 'array-shuffle';
import {InventoryColor, InventoryColorContract} from './InventoryColor';
import {InventoryBrand, InventoryBrandContract} from './InventoryBrand';
import {InventoryLocation, InventoryLocationContract} from './InventoryLocation';

export interface InventoryUpdateResponseContract {
  color: InventoryColorContract;
  result: Boolean;
}

export class InventoryUpdateResponse {
  color: InventoryColor;
  result: Boolean;

  constructor(contract: InventoryUpdateResponseContract) {
    // console.log('Mapping color response');
    if (contract != null) {
      this.color = new InventoryColor(contract.color);
      this.result = contract.result;
    } else {
      this.result = false;
    }
  }
}
