import * as shuffler from 'array-shuffle';
import {InventoryColor, InventoryColorContract} from './InventoryColor';
import {InventoryBrand, InventoryBrandContract} from './InventoryBrand';
import {InventoryLocation, InventoryLocationContract} from './InventoryLocation';

export interface InventoryResponseContract {
  colors: InventoryColorContract[];
  brands: InventoryBrandContract[];
  locations: InventoryLocationContract[];
}

export class InventoryResponse {
  colors: InventoryColor[];
  brands: InventoryBrand[];
  locations: InventoryLocation[];

  constructor(contract: InventoryResponseContract) {
    // console.log('Mapping color response');
    if (contract != null) {
      this.colors = contract.colors.map(item => new InventoryColor(item));
      this.brands = contract.brands.map(item => new InventoryBrand(item));
      this.locations = contract.locations.map(item => new InventoryLocation(item));
    } else {
      this.colors = [];
      this.brands = [];
      this.locations = [];
    }
  }
}
