import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ColorsComponent} from './colors/colors.component';
import {DetailComponent} from './detail/detail.component';
import {InventoryComponent} from './inventory/inventory.component';

const routes: Routes = [
  { path: 'colors', component: ColorsComponent },
  { path: '', redirectTo: 'colors', pathMatch: 'full' },
  { path: 'color/:id', component: DetailComponent},
  { path: 'inventory', component: InventoryComponent},
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [
    RouterModule
  ],
})

export class AppRoutingModule { }
