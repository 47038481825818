<mat-card>
  <mat-card-title>
    {{color.brandColor}}
  </mat-card-title>
  <mat-card-content>
    {{color.stock}}
  </mat-card-content>
  <mat-card-actions>
    <button mat-button [disabled]="!enabled" (click)="updateInventory(color)">Remove</button>
  </mat-card-actions>
</mat-card>
