import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {ColorsComponent} from './colors/colors.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {DetailComponent} from './detail/detail.component';
import {AppRoutingModule} from './app-routing.module';
import {AngularFireModule} from '@angular/fire';
import {environment} from 'environments/environment';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {BackendService} from './server/backend.service';
import {MatCardModule} from '@angular/material/card';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgxSpinnerModule} from 'ngx-spinner';
import {InventoryComponent} from './inventory/inventory.component';
import {SessionService} from './session.service';
import {TokenInterceptor} from './token-interceptor';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import { InventoryColorCardComponent } from './inventory/inventory-color-card/inventory-color-card.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';


@NgModule({
  declarations: [
    AppComponent,
    ColorsComponent,
    DetailComponent,
    InventoryComponent,
    InventoryColorCardComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    MatSelectModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    MatCardModule,
    MatButtonModule,
    MatTabsModule,
    HttpClientModule,
    NgxSpinnerModule,
    MatSnackBarModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true},
    BackendService,
    SessionService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
