import {ColorAirtable, ColorContract} from './ColorAirtable';
import {PriceTierAirtable, PriceTierContract} from './PriceTierAirtable';
import {ColorCategoryAirtable, ColorCategoryContract} from './ColorCategoryAirtable';
import * as shuffler from 'array-shuffle';

export interface ColorResponseContract {
  count: number;
  colors: ColorContract[];
  priceTiers: PriceTierContract[];
  colorCategories: ColorCategoryContract[];
}

export class ColorResponseAirtable {
  count: number;
  colors: ColorAirtable[];
  priceTiers: PriceTierAirtable[];
  colorCategories: ColorCategoryAirtable[];

  constructor(contract: ColorResponseContract) {
    console.log('Mapping color response');
    if (contract != null) {
      this.count = contract.count;
      this.colors = shuffler(contract.colors.map(item => new ColorAirtable(item)));
      this.priceTiers = contract.priceTiers.map(item => new PriceTierAirtable(item));
      this.colorCategories = contract.colorCategories.map(item => new ColorCategoryAirtable(item));
    } else {
      this.count = 0;
      this.colors = [];
      this.priceTiers = [];
      this.colorCategories = [];
    }
  }
}
