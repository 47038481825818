export interface PriceTierContract {
  name: string;
  id: string;
}

export class PriceTierAirtable {
  name: string;
  id: string;

  // https://stackoverflow.com/questions/51763745/angular-6-error-typeerror-is-not-a-function-but-it-is
  constructor(contract: PriceTierContract) {
    Object.assign(this, contract);
  }
}
